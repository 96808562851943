import React from 'react';
import styled from 'styled-components';
import { FaGithub, FaLinkedin, FaEnvelope } from 'react-icons/fa'; // Import des icônes

const ContactSection = styled.section`
  background-color: #C68484;
  color: white;
  padding: 50px 0;
  text-align: center;
`;

const ContactContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const ContactTitle = styled.h2`
  font-size: 50px;
  margin-bottom: 20px;
  @media (max-width: 425px) {
    font-size: 30px;
    margin-bottom: 50px;
  }
`;

const ContactIcons = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
`;

const IconLink = styled.a`
  color: white;
  font-size: 25px;
  transition: transform 0.3s ease, color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  &:hover {
    transform: translateY(-5px);
    color: #EEEEEE;
  }
`;

const ContactText = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
`;

function Contact() {
  return (
    <ContactSection id="contact">
      <ContactContainer>
        <ContactTitle>Contactez-moi</ContactTitle>
        <ContactText>
          <IconLink href="mailto:derraz.ines@gmail.com">
            <FaEnvelope /> derraz.ines@gmail.com
          </IconLink>
        </ContactText>
        <ContactIcons>
          <IconLink href="https://github.com/inesder" target="_blank" rel="noopener noreferrer">
            <FaGithub />
          </IconLink>
          <IconLink href="https://www.linkedin.com/in/inesderraz/" target="_blank" rel="noopener noreferrer">
            <FaLinkedin />
          </IconLink>
        </ContactIcons>
      </ContactContainer>
    </ContactSection>
  );
}

export default Contact;
