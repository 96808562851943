import React from 'react';
import styled from 'styled-components';
import {
  FaReact,
  FaJs,
  FaHtml5,
  FaCss3Alt,
  FaNodeJs,
} from 'react-icons/fa';
import { SiMongodb, SiSass, SiJest, SiFigma } from 'react-icons/si'; // Import des autres icônes
import aboutAvatar from '../assets/aboutAvatar.png'

const AboutSection = styled.section`
  background-color: #A1B3B6;
  color: white;
  padding: 50px 20px;
  width: 100%; 
`;

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  gap: 30px;

  @media (min-width: 768px) {
    flex-direction: row; /* Alignement horizontal sur les écrans larges */
    align-items: center;
    gap: 90px;
  }
`;


const AboutTitle = styled.h2`
  font-size: 50px;
  margin: 0 0 20px 0;
  text-align: center;

  @media (max-width: 425px) {
    font-size: 30px;
    margin: 0 0 50px 0;
  }
`;

const AboutImage = styled.img`
  width: 260px;
  max-width: 300px; /* Taille maximum pour les écrans desktop */
  border-radius: 50%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;

  @media (max-width: 1024px) {
    width: 200px; /* Réduction progressive pour les tablettes */
  }

  @media (max-width: 768px) {
    width: 180px; 
    margin: 0 auto; 
  }
`;


const AboutTextContainer = styled.div`
  flex: 1;
  text-align: left;

  @media (max-width: 425px) {
    text-align: center;
  }
`;

const AboutText = styled.p`
  font-size: 18px;
  line-height: 1.6;
  font-weight: 400;
  margin-bottom: 20px;

  @media (max-width: 425px) {
    font-size: 16px;
  }
`;

const SkillsContainer = styled.div`
  margin-top: 20px;
`;

const SkillsTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 30px;
`;

const SkillsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;

  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`;

const SkillIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  font-size: 30px;
  color: white;
  transition: transform 0.3s ease, color 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    color: #eeeeee;
  }

  span {
    font-size: 14px;
    font-weight: 500;
  }
`;

function About() {
  return (
    <AboutSection id="about">
      <AboutTitle>À propos de moi</AboutTitle>
      <AboutContainer>
        <AboutImage
          src={aboutAvatar}
          alt="Portrait de développeuse"
        />
        <AboutTextContainer>
          <AboutText>
            Passionnée par le <strong>développement web</strong>, j’ai découvert ce domaine en réalisant mes premiers projets et en explorant l’univers du code. <strong>Titulaire d’un bac+2 en développement web</strong>, validé en <strong>alternance</strong>, j’ai consolidé mes compétences techniques et professionnelles grâce à des expériences variées.
          </AboutText>
          <AboutText>
            Actuellement, je poursuis une formation de <strong>développeuse d’applications JavaScript/React pour valider un bac+4</strong>. Mon objectif est de créer des interfaces <strong>modernes, intuitives</strong> et adaptées aux besoins des utilisateurs, en mettant toujours l'accent sur la qualité et <strong>l'innovation</strong>.
          </AboutText>
          <SkillsContainer>
            <SkillsTitle>Mes compétences</SkillsTitle>
            <SkillsList>
              <SkillIcon>
                <FaJs />
                <span>JavaScript</span>
              </SkillIcon>
              <SkillIcon>
                <FaReact />
                <span>React</span>
              </SkillIcon>
              <SkillIcon>
                <FaHtml5 />
                <span>HTML</span>
              </SkillIcon>
              <SkillIcon>
                <FaCss3Alt />
                <span>CSS</span>
              </SkillIcon>
              <SkillIcon>
                <SiSass />
                <span>SASS</span>
              </SkillIcon>
              <SkillIcon>
                <FaNodeJs />
                <span>Node.js</span>
              </SkillIcon>
              <SkillIcon>
                <SiMongodb />
                <span>MongoDB</span>
              </SkillIcon>
              <SkillIcon>
                <SiJest />
                <span>Jest</span>
              </SkillIcon>
              <SkillIcon>
                <SiFigma />
                <span>Figma</span>
              </SkillIcon>
            </SkillsList>
          </SkillsContainer>
        </AboutTextContainer>
      </AboutContainer>
    </AboutSection>
  );
}

export default About;

