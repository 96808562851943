import React from 'react';
import styled from 'styled-components';

const FooterSection = styled.footer`
  color: #9B4444;
  text-align: center;
  padding: 20px 20px;
  font-size: 14px;
`;

const FooterText = styled.p`
  margin: 0;
  line-height: 1.6;
`;

function Footer() {
  return (
    <FooterSection>
      <FooterText>© {new Date().getFullYear()}. Tous droits réservés.</FooterText>
      <FooterText>Developed and Designed by Inès Derraz</FooterText>
    </FooterSection>
  );
}

export default Footer;
