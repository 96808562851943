import React, { useState } from 'react';
import styled from 'styled-components';

const StyledNav = styled.nav`
  margin-right: 100px;
  position: relative; /* Pour positionner le menu hamburger */
`;

const StyledUl = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex; 
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 768px) {
    flex-direction: column;
    background-color: white;
    position: fixed;
    top: 60px; /* Ajustez selon la hauteur de votre navbar */
    right: 0;
    width: 50%;
    display: ${({ open }) => (open ? 'flex' : 'none')}; /* Affiche ou masque le menu */
    align-items: center;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Optionnel : ajoute une ombre */
    z-index:999;
  }
`;

const StyledLi = styled.li`
  margin-right: 30px;

  @media (max-width: 768px) {
    margin: 15px 0;
    margin-right: 0;
  }
`;

const StyledLink = styled.a`
  color: #9B4444;
  text-decoration: none; 
  font-weight: 500;
  font-size: 20px;
  &:hover {
    color: #C68484; 
  }
`;


const Hamburger = styled.div`
  display: none; /* Caché sur les écrans larges */

  @media (max-width: 768px) {
    display: block;
    position: fixed;
    top: 25px;
    right: 45px;
    cursor: pointer;
    z-index: 1000;
  }

  div {
    width: 25px;
    height: 3px;
    background-color: #9B4444;
    margin: 5px 0;
    transition: 0.4s;
  }

  &.open div:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  &.open div:nth-child(2) {
    opacity: 0;
  }

  &.open div:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }
`;

function Navbar() {
  const [open, setOpen] = useState(false);

  const handleScroll = (event, targetId) => {
    event.preventDefault(); // Empêche le comportement par défaut du lien
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
      setOpen(false); // Ferme le menu après le clic (utile sur mobile)
    }
  };  

  return (
    <StyledNav>
      <Hamburger onClick={() => setOpen(!open)} className={open ? 'open' : ''}>
        <div></div>
        <div></div>
        <div></div>
      </Hamburger>
      <StyledUl open={open}>
      <StyledLi>
      <StyledLink href="#about" onClick={(e) => handleScroll(e, 'about')}>À propos</StyledLink>
    </StyledLi>
    <StyledLi>
      <StyledLink href="#projects" onClick={(e) => handleScroll(e, 'projects')}>Projets</StyledLink>
    </StyledLi>
    <StyledLi>
      <StyledLink href="#contact" onClick={(e) => handleScroll(e, 'contact')}>Contact</StyledLink>
    </StyledLi>


      </StyledUl>
    </StyledNav>
  );
}

export default Navbar;
