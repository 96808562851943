import React from 'react';
import styled from 'styled-components';
import projectsDatas from '../datas/projectsDatas.json'
import Kasa from '../assets/projects/Kasa.png';
import Lespetitsplats from '../assets/projects/Lespetitsplats.png'
import Fisheye from '../assets/projects/Fisheye.png'
import Gameon from '../assets/projects/Gameon.png'
import Billed from '../assets/projects/Billed.png'
import Learnathome from '../assets/projects/Learnathome.png'
import Lapanthère from '../assets/projects/Lapanthère.png'
import Ohmyfood from '../assets/projects/Ohmyfood.png'
import Kanap from '../assets/projects/Kanap.png'
import SportSee from '../assets/projects/SportSee.png'

// Dictionnaire d'images
const images = {
  "Kasa.png": Kasa,
  "Lespetitsplats.png": Lespetitsplats,
  "Fisheye.png": Fisheye,
  "Gameon.png": Gameon,
  "Billed.png": Billed,
  "Learnathome.png": Learnathome,
  "Lapanthère.png": Lapanthère,
  "Ohmyfood.png": Ohmyfood,
  "Kanap.png": Kanap,
  "SportSee.png": SportSee
};

const ProjectsSection = styled.section`
  background: #EEEEEE;
  padding: 50px 0;
  id: projects;
`;

const ProjectsContainer = styled.div`
display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const ProjectsTitle = styled.h2`
color:#9B4444;
text-align: center;
font-size: 50px;
margin: 0 0 50px 0;
@media (max-width: 425px) {
  font-size: 30px;
}
`

const ProjectCard = styled.div`
  width: 390px;
  height: 600px;
  margin: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-10px);
  }

  @media (max-width: 425px){
    width: auto;
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 270px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
`;

const ProjectInfo = styled.div`
  padding: 15px;
`;

const ProjectTitle = styled.h3`
  margin-top: 0;
  color:#9B4444;
`;

const ProjectDescription = styled.p`
  color: #666;
`;

const ProjectTechnologiesLabel = styled.p`
font-weight: 500;
color:#9B4444
`

const ProjectTechnologies = styled.p`

color: #666;
`

const ProjectLink = styled.a`
  text-decoration: none;

`;

function Projects() {
  return (
    <ProjectsSection id='projects'>
      <ProjectsTitle>Mes projets</ProjectsTitle>
      <ProjectsContainer>
        {projectsDatas.map(project => (
          <ProjectLink key={project.id} href={project.link}>
            <ProjectCard>
              <ProjectImage src={images[project.image]} alt={project.title} />
              <ProjectInfo>
                <ProjectTitle>{project.title}</ProjectTitle>
                <ProjectDescription>{project.description}</ProjectDescription>
                <ProjectTechnologiesLabel>Technologies : </ProjectTechnologiesLabel>
                <ProjectTechnologies>{project.technologies}</ProjectTechnologies>

              </ProjectInfo>
            </ProjectCard></ProjectLink>
        ))}
      </ProjectsContainer>
    </ProjectsSection>
  );
}

export default Projects;
