import React from 'react';
import styled, { keyframes } from 'styled-components';
import avatar from '../assets/avatar.png';
import laptop from '../assets/laptop.png';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  padding: 50px 20px;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 30px;
    padding: 80px 20px;
  }
`;


const ImagesContainer = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;

  @media (max-width: 768px) {
    gap: 20px;
  }
`;


const StyledAvatar = styled.img`
  width: 300px;
  max-width: 50%;
  height: auto;
  animation: ${fadeIn} 1s ease-out;

  @media (max-width: 768px) {
    width: 200px;
    max-width: 50%;
  }

  @media (max-width: 425px) {
    width: 150px;
  }
`;


const StyledLaptop = styled.img`
  width: 300px;
  max-width: 40%;
  height: auto;
  align-self: flex-end;
  animation: ${fadeIn} 1s ease-out;
  animation-fill-mode: backwards;

  @media (max-width: 768px) {
    width: 200px;
    max-width: 50%;
  }

  @media (max-width: 425px) {
    width: 120px;
  }
`;


const StyledInformationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #9b4444;
  text-align: left;
  animation: ${fadeIn} 1s ease-out;
  animation-fill-mode: backwards;

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
  }

  @media (max-width: 425px) {
    align-items: center;
    text-align: center;
  }
`;


const StyledTitle = styled.h1`
  font-size: 3.5rem;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }

  @media (max-width: 425px) {
    font-size: 2rem;
  }
`;


const StyledSubtitle = styled.h2`
  font-size: 2.5rem;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 425px) {
    font-size: 1.5rem;
  }
`;


const StyledButton = styled.button`
  padding: 15px 30px;
  background-color: #9b4444;
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  margin-top: 30px;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    background-color: #c68484;
  }

  @media (max-width: 768px) {
    width: 80%;
  }

  @media (max-width: 425px) {
    width: 100%;
  }
`;


function Header() {
  return (
    <StyledHeader>
      <ImagesContainer>
        <StyledAvatar src={avatar} alt="avatar" />
        <StyledLaptop src={laptop} alt="laptop" />
      </ImagesContainer>
      <StyledInformationsContainer>
        <StyledTitle>Inès Derraz</StyledTitle>
        <StyledSubtitle>Développeuse Web</StyledSubtitle>
        <StyledButton onClick={() => document.getElementById('projects').scrollIntoView({ behavior: 'smooth' })}>
          Découvrez mes projets
        </StyledButton>
      </StyledInformationsContainer>
    </StyledHeader>
  );
}

export default Header;
